<!--
  Provides various utilities for development and testing.
  SECURITY: This view should NOT be present in production environments.
-->
<template>
  <div class="utilities-container">
    <TextInput
      v-model="marketContractId"
      label="Market Contract ID"
      placeholder="i.e. blkdrp-market"
    />
    <button class="standard" @click="createMarketContract">Create Market Contract</button>
    <br><br>

    <button class="standard" @click="bridgeTokenCheck">Check Bridge Token</button>
    <br><br>

    <button class="standard" @click="generateClaimsData">Generate Claims Data</button>
    <br><br>

    <div class="utility-group generate-claims">
      <TextInput
        v-model="targetCollection"
        small
        label="Target Collection"
        placeholder="i.e. nft_a8bb25fe98.blackdrop.collab-land.testnet"
      >
      </TextInput>
      <div
        class="email-input"
        v-for="(email, index) in emails"
        :key="`email-${index}`" label="email"
      >
        <TextInput
          v-model="emails[index]"
          small
        >
        </TextInput>
        <button class="standard" @click="emails.splice(index, 1)">Remove</button>
      </div>
      <button
        class="standard"
        @click="emails.push('')"
      >
        Add email
      </button>
      <br><br>
      <button class="standard" @click="generateClaims">Generate Claims</button>
    </div>

    <button class="standard" @click="createClaimsCsv()">Create Claims CSV</button>
    <br><br>

    <div class="utility-group">
      <input
        type="file"
        @change="onFileChange"
      >
      <button class="standard" @click="cloudinaryUpload">Upload to Cloudinary</button>

      <button class="standard" @click="addToIPFS">Pin to IPFS</button>
    </div>

    <button class="standard" @click="getOneXRunListings">Get 1XRUN Listings</button>
    <br><br>
    <button class="standard" @click="sendFunds">Send {{ nearSendAmount }} NEAR</button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import { utils } from 'near-api-js'
import { doc, query, getDoc, getDocs, setDoc, increment } from 'firebase/firestore'

export default {
  async created () {
    // const res = await this.fbCall('queryShopifyOrders')
    // console.log(res)
  },
  data () {
    return {
      marketContractId: '',
      targetCollection: '',
      rarity: ['open', 'silver', 'gold', 'diamond', 'utopia'],
      tokens: [
        'v01.open',
        'v01.silver',
        'v01.gold',
        'v01.diamond',
        'v02.open',
        'v02.silver',
        'v02.gold',
        'v02.diamond'
        // 'v03.open',
        // 'v03.silver'
      ],
      emails: [
        // 'matthew@doubledayandcartwright.com',
        // 'matthew@blkmnt.io',
        'blurden@gmail.com',
        // 'charles@travisscott.com',
        // 'david@travisscott.com',
        // 'db@travisscott.com',

        'teddy@v0.run',
        'theodoremichels@gmail.com',

        'cody@1xrun.com',
        'jesse@1xrun.com',
        'dan@1xrun.com'
      ],
      rawFileData: null,
      fileData: null,
      ipfsUrl: 'testing testing',
      nearSendAmount: '0.01'
    }
  },
  computed: {
    ...mapGetters([
      'globalSettings',
      'marketData',
      'userProfile',
      'userNearSignature',
      'nearConnection',
      'currentUser'
    ])
  },
  methods: {
    async bridgeTokenCheck () {
      try {
        const res = await this.fbCall('bridge-checkBridgeToken')
        console.log(res)
      } catch (err) {
        console.log(err)
      }
    },
    /** Manually (brute force) calculate claims data from claims documents. */
    async generateClaimsData () {
      console.log('Generating sales data.')
      const cdSales = {}
      const cdCollections = {}
      const globalTotals = {
        totalClaims: 0,
        totalClaimed: 0
      }
      const claims = await getDocs(this.$fb.claimsCollection)

      // For each claim...
      claims.forEach((claim) => {
        if (claim.id === 'metadata') return
        const cd = claim.data()
        const saleId = claim.sale || 'sale-undefined'
        // console.log(cd)
        if (!cdCollections[cd.collection]) {
          cdCollections[cd.collection] = {
            totalClaims: 1,
            totalClaimed: 0,
            types: {}
          }
        } else {
          cdCollections[cd.collection].totalClaims++
        }

        if (!cdSales[saleId]) {
          cdSales[saleId] = {
            totalClaims: 1,
            totalClaimed: 0,
            types: {}
          }
        } else {
          cdSales[saleId].totalClaims++
        }
        globalTotals.totalClaims++

        if (!cdCollections[cd.collection].types[cd.type]) {
          cdCollections[cd.collection].types[cd.type] = { total: 0, claimed: 0 }
        } else {
          cdCollections[cd.collection].types[cd.type].total++
        }
        if (cd.claimed) {
          cdCollections[cd.collection].types[cd.type].claimed++
          cdCollections[cd.collection].totalClaimed++
          globalTotals.totalClaimed++
        }

        if (!cdSales[saleId].types[cd.type]) {
          cdSales[saleId].types[cd.type] = { total: 0, claimed: 0 }
        } else {
          cdSales[saleId].types[cd.type].total++
        }
        if (cd.claimed) {
          cdSales[saleId].types[cd.type].claimed++
          cdSales[saleId].totalClaimed++
        }
      })
      console.log('By collections', cdCollections)

      for (const collection in cdCollections) {
        const data = cdCollections[collection]
        setDoc(doc(this.$fb.db, 'claim-data-collections', collection), {
          totalClaims: data.totalClaims,
          totalClaimed: data.totalClaimed
        })
        for (const type in data.types) {
          setDoc(doc(this.$fb.db, 'claim-data-collections', collection, 'types', type), data.types[type])
        }
      }
      setDoc(doc(this.$fb.db, 'claim-data-collections', 'global'), globalTotals)
    },

    /** Generate claims for all emails in the default-sale */
    async generateClaims () {
      console.log(`Generating claims for ${this.targetCollection}...`)

      const collectionTypes = []
      const types = await getDocs(query(this.$fb.db, 'token-contracts', this.targetCollection, 'types'))
      types.forEach((type) => {
        const copy = type.data()
        copy.id = type.id
        collectionTypes.push(copy)
      })
      console.log(collectionTypes)
      const claimsPerType = 2
      // Loop through everyone who should receive a claim...
      for (const email of this.emails) {
        // Give them an amount of each type...
        for (const type of collectionTypes) {
          // Do a number of times according to claimsPerType.
          for (let i = 0; i < claimsPerType; i++) {
            // const rand = Math.floor(Math.random() * type.cap) + 1
            let current = 1
            const counters = await getDoc(doc(this.$fb.db, 'claims', 'metadata'))
            if (counters.exists() && (counters.data())[type.id]) current = (counters.data())[type.id] + 1
            console.log(`Generating claim for ${type.id}.${current}.`)
            const randId = Math.floor(Math.random() * 100000)
            const target = doc(this.$fb.db, 'claims', `${randId}`)
            const targetDoc = await getDoc(target)
            // Don't overwrite an existing claim
            if (!targetDoc.exists()) {
              await setDoc(target, {
                email: email,
                claimed: false,
                typePrimary: type.typePrimary.id,
                typeSecondary: type.typeSecondary.id,
                collection: this.targetCollection
              })
              await setDoc(doc(this.$fb, 'claims', 'metadata'), {
                [type.id]: increment(1)
              }, { merge: true })
            } else {
              console.log('That claim already exists.')
            }
          }
        }
      }
      console.log('Done')
    },

    createClaimsCsv () {
      const numClaims = 100
      let csv = 'email,token\r\n'
      for (let i = 0; i < numClaims; i++) {
        const rv = (Math.floor(Math.random() * 10) + 1).toString().padStart(2, '0')
        const ri = Math.floor(Math.random() * this.rarity.length)
        const randToken = `v${rv}.${this.rarity[ri]}`
        const randEmail = this.emails[Math.floor(Math.random() * this.emails.length)]
        csv += `${randEmail},${randToken}\r\n`
      }
      console.log(csv)
      const hiddenElement = document.createElement('a')
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
      hiddenElement.target = '_blank'
      hiddenElement.download = 'claims.csv'
      hiddenElement.click()
    },
    async createMarketContract () {
      console.log('Creating new market contract...')
      try {
        const newContract = await this.fbCall('minting-newMarketContract', this.marketContractId)
        alert(newContract.data)
      } catch (err) {
        console.log(err)
      }
    },

    onFileChange (e) {
      console.log('New file uploaded.')
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      const reader = new FileReader()
      reader.onload = () => {
        this.rawFileData = reader.result // Raw image data
      }
      reader.readAsDataURL(files[0])
      this.fileData = files[0]
    },

    // Note: Don't create the type folder on Cloudinary manually, as it seems to interfere with alternate image size generation.
    /* eslint-disable no-unused-vars */
    async cloudinaryUpload () {
      const targetCollection = 'testing.testnet'
      const targetType = 'genesis.alpha'
      const path = `generated-assets/${targetCollection}/image`
      // const path = `generated-assets/${targetCollection}/${targetType}/video`
      // const path = `generated-assets/${process.env.VUE_APP_MARKET_ID}/collection-groups/image`
      // const path = 'test/image'
      console.log(this.rawFileData)
      const res = await this.fbCall('cloudinary-uploadAsset', {
        // path: `stores/${process.env.VUE_APP_MARKET_ID}/logo`,
        path: path,
        asset: this.rawFileData
      })
      console.log(res)
    },
    /* eslint-enable no-unused-vars */

    async addToIPFS () {
      console.log('Pinning')
      const hash = await this.addAssetToIpfs(this.fileData)
      console.log(this.getIpfsUrl(hash))
    },

    async sendFunds () {
      const account = await this.nearConnection.account(this.userProfile.nearId)
      const amount = utils.format.parseNearAmount(this.nearSendAmount)
      console.log('Sending', amount)
      const res = await account.sendMoney(
        'blkdrp.testnet',
        amount
      )
      console.log(res)
    },

    async getOneXRunListings () {
      console.log('Getting 1XRUN Listings...')
      // POST data should be sent as stringified JSON
      const res = await axios.post('https://www.1xrun.com/vendors/ajax/nft-product.php', JSON.stringify({
        connect_id: 'nK6KxH',
        user_key: 'G1Rfje6uMgdoDdla1Lbx2k7tQX1AgsNdIq',
        rid: '00966a'
      }))
      console.log(res)
    }
  }
}
</script>

<style lang="scss" scoped>
input[type="file"] {
}
::-webkit-file-upload-button {
  background: none;
  border: solid 1px $blk-white;
}
.utility-group {
  padding: $space-s;
  border: solid 1px $blk-white;
  border-radius: $space-s;
  margin: $space-m 0;
}
.generate-claims {
  .email-input .field-container {
    display: inline-block;
    width: 50%;
  }
}
</style>
